@use "variables" as var;
@use 'sass:math';


/* media queries*/
@mixin max-width($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin min-width($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin max-height($height) {
  @media (max-height: $height) {
    @content;
  }
}

@mixin min-height($height) {
  @media (min-height: $height) {
    @content;
  }
}

/* mixin for hover supported devices */
@mixin hover-supported {
  @media not all and (hover: none) {
    &:hover {
      @content;
    }
  }
}

/* container */
@mixin container($maxW: false) {
  @if $maxW {width: min(100% - 48px, #{$maxW});}
  margin-inline: auto;
  background-size: cover;
  background-repeat: no-repeat;
}

/*  pseudo element */
@mixin pseudo($content: '', $position: false, $display: block) {
  content: $content;
  @if $position {position: $position};
  display: $display;
}

@mixin position($position: false, $left: false, $right: false, $bottom: false, $top: false, $width: 100%) {
  @if $position {position: $position};
  @if $left {left: $left};
  @if $right {right: $right};
  @if $bottom {bottom: $bottom};
  @if  $top {top: $top};
  width: $width;
}

/* Flex */
@mixin d-flex(
  $justify-content: false,
  $align-items: false,
  $direction: false,
  $wrap: false) {
  display: flex;
  @if $justify-content {justify-content: $justify-content};
  @if $align-items {align-items: $align-items};
  @if $direction {flex-direction: $direction;}
  @if $wrap {flex-wrap: $wrap;}
}

/* visible */
@mixin visible($zIndex: 1) {
  opacity: 1;
  visibility: visible;
  z-index: $zIndex;
  pointer-events: auto;
}

/* hidden */
@mixin hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

/*   fluid params in px */
$maxWidthContainer: 1472; // container size + padding
$maxWidth: 1512; // size of design

@mixin adaptiv-value($property, $pcSize, $mobSize, $type) { $addSize: $pcSize - $mobSize; @if $type==1 {
  //only if less then container
  #{$property}: $pcSize + px; @media (max-width: #{$maxWidthContainer + px}) { #{$property}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 375px) / #{$maxWidthContainer - 375})); }
}
}
