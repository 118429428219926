@use "base" as *;

/* title sizes */
.h1, h1 {
  font: 800 $h1/1.2 $ff-nunito; // 62px-36px
}
.h2, h2 {
  font: 800 $h2/1.44 $ff-nunito; //48px-28px
}
.h3, h3 {
  font: 600 $h3/normal $ff-nunito; //36px-20px
}
.h4, h4 {
  font: 700 $h4/normal $ff-nunito;
}
.h1, h1, .h2, h2, .h3, h3, .h4, h4 {
  color: $title-black;
}

.section-title {
  margin-bottom: clamp(24px, 4vw, 64px);
  text-align: center;
  max-width: 1100px;
  margin-inline: auto;
}

/* text sizes */

.text-xs {
  font: 400 #{$text-xs}/1.44 $default-font; //14px
}
.text-sm {
  font: 400 #{$text-sm}/1.44 $default-font; //16px
}
.text-md {
  font: 400 #{$text-md}/1.44 $default-font; //20px - 16px
}

/* typography alignments */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

/* typography utility classes */
.text-underline {
  text-decoration: underline;
}
.text-underline-hover {
  text-decoration: underline transparent;
  @include hover-supported {
    text-decoration-color: inherit;
  }
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* text boldness */
.fw-400 {
  font-weight: 400; //regular
}
.fw-500 {
  font-weight: 500; //medium
}
.fw-600 {
  font-weight: 600; //semibold
}
.fw-700 {
  font-weight: 700; //bold
}
.fw-800 {
  font-weight: 800; //extra bold
}

.ff-nunito {
  font-family: $ff-nunito;
}
.ff-basic {
  font-family: $default-font;
}

/* text colors */
.t-color-gray {
  color: $text-gray;
}
.t-accent-orange {
  color: $accent-orange;
}
.title-black {
  color: $title-black;
}


// max-width 991.9px
@include max-width($tablet) {
  .text-center-mob {
    text-align: center;
  }
  .section-title {
    text-align: left;
  }
}

// max-width 767.9px
@include max-width($mobile-res) {
  .h1, h1, .h2, h2 {
    line-height: 1.18;
  }
}
