@use "../base" as *;

.site-footer {
  border-top: 1px solid $gray;
  padding-block: 14px;

  &__inner {
    font: 600 1.125rem/1 $ff-nunito; //18px
    gap: 36px 68px;
  }
  &__content-wrap {
    gap: 36px 68px;
  }

  // menu
  .footer-menu {

    gap: 20px 48px;
    a {
      @include hover-supported{
        color: $accent-orange;
      }
    }
  }

  .footer-socials {

    //padding-top: 10px;
    &__row {
      > p {
        min-width: 95px;
      }
    }
    &__row , ul {
      gap: 24px;
    }

    svg {
      display: block;
    }
  }

  &__copyright {
    padding-top: 36px;
  }

  // min-width 992px
  @include min-width($laptop) {
    &__logo {
      margin-block: -6px;
    }
    .footer-socials {
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      gap: 24px;
    }
  }

  // max-width 991.9px
  @include max-width($tablet) {
    padding-block: 25px 36px;
    &__inner {
      flex-direction: column;
      align-items: start;
    }
    &__content-wrap {
      flex-direction: column;
      align-items: start;
    }
    .footer-socials {
      > p {
        margin-bottom: 12px;
      }
      > ul:first-of-type {
        margin-bottom: 36px;
      }
    }
  }
}
