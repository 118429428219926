@use "../base" as *;

.cta-badge {
  width: fit-content;
  margin-bottom: clamp(48px, 4vw, 64px);
  background-color: $accent-orange;
  padding: 6px 16px;
  font: 600 #{$text-sm}/1 $default-font; //16px
  color: white;
  border-radius: 24px;
}
