@use "../base" as *;

.faq {
  .container {
    padding: clamp(26px, 5vw, 64px) clamp(16px, 3.5vw, 40px);
    gap: 48px 20px;
  }
  h2 {
    line-height: 1.18;
  }
  &__text-block {
    max-width: 600px;
    width: 100%;
    padding-right: clamp(10px, 1.5vw, 30px);
    gap: clamp(10px, 2vw, 15px);
  }
  &__subtitle {
    color: #A9A9A9;
  }

  //max-width 767px
  @include max-width($mobile-res) {
    .container {
      grid-template-columns: unset;
    }
  }
}
