@use "../base" as *;

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  /* sizes */
  &--sm {
    width: 20px;
    height: 20px;
  }
  &--md {
    width: 24px;
    height: 24px;
  }

  /* icons */
  &--arrow {
    position: relative;
    @include d-flex(center, center);
    &:before {
      @include pseudo();
      width: 8px;
      height: 8px;
      border-top: 2px solid $lite-black;
      border-left: 2px solid $lite-black;
      transition: $transition-300;
    }
    &-down {
      transform: rotate(-135deg);
    }
  }
  &--plus {
    background-image: url('');
  }
}
