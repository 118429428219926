
/* colors */
$title-black: #2E2E2E;
$text-black: #434343;
$text-gray: #FAFAFA;

$black: #2E2E2E;
$lite-black: #434343;
$accent-orange: #FF6600;
$pink: #FFE6D6;
$pink-light: #FCF1EA;
$gray: #F4F4F4;
$dark-gray: #C0C0C0;
$bg-gray: #FAFAFA;

$gradient-beta: linear-gradient(89.75deg, #23FF1F 0.22%, #5FCFFF 98.55%);

/* fonts */
$default-font: 'Montserrat', Arial,  sans-serif;
$ff-nunito: 'Nunito', Arial,  sans-serif;

/* font sizes */
$h1: clamp(36px, min(4vw, 8vh), 62px);
$h2: clamp(28px, min(3.5vw, 6.3vh), 48px);
$h3: clamp(20px, min(2.5vw, 4.5vh), 36px);
$h4: clamp(16px, 3vw, 20px);

$text-xs: 0.875rem;
$text-sm: 1rem;
$text-md: clamp(16px, 1.9vw, 20px);

/* transition timing */
$transition-200: .25s;
$transition-300: .35s;
$transition-400: .4s;
$btn-transition: all .3s;

/* resolutions */
$mobile-res: 767.9px;
$desktop-res: 768px;
$tablet: 991.9px;
$laptop: 992px;
