@use "../base" as *;

.how-it-works {
  &__list {
    max-width: 600px;
  }
  .phone {
    &__image {
      @include position(absolute);
      z-index: 1;
      pointer-events: none;
    }
    &-wrap {
      padding: 6.7%;
    }
  }

  // how-it-works item
  .how-to-item {
    gap: clamp(16px, 3vw, 36px);
    cursor: pointer;
    transition: $transition-400;
    &__circle {
      @include d-flex(center, center);
      width: clamp(36px, 6.5vw, 56px);
      height: clamp(36px, 6.5vw, 56px);
      padding: clamp(6px, 1.5vw, 10px);
      border-radius: 50%;
      background-color: $accent-orange;
    }
    h3 {
      margin-bottom: clamp(8px, 1.5vw, 10px);
    }
  }

  // min-width 992px
  @include min-width($laptop) {
    &__list {
      width: 50%;
    }
  }

  // min-width 767px
  @include min-width($desktop-res) {
    #steps-slider-mob-res {
      display: none;
    }
    &__phone {
      max-width: 317px;
      width: min(30%, 40vh);
    }
    // how-it-works item
    .how-to-item {
      &:not(:last-of-type) {
        margin-bottom: 4%;
      }
      &:not(.active) {
        opacity: .3;
      }
    }
    .slick-slide {
      cursor: grab;
    }

  }
  // max-width 991px
  @include max-width($tablet) {
    &__list {
      width: 55%;
    }
    &__inner {
      justify-content: space-between;
    }
  }

  // max-width 767px
  @include max-width($mobile-res) {
    &__inner {
      display: block;
    }
    &__list {
      width: 100%;
    }
    &__phone {
      max-width: 223px;
      margin-inline: auto;
    }
    .how-to-item {
      margin-bottom: 24px;
    }

    .slick-slide {
      //margin-inline: 10px;
    }

  }
}
