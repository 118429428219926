@use "../base" as *;

.logotype-block {
  h2 {
    margin-bottom: clamp(12px,1.5vw,22px);
  }

  .logotype-list {
    gap: clamp(36px, 5vw, 72px);
  }
}
