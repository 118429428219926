@use "../base" as *;

.cta {
  .container {
    background: $black;
    border-radius: clamp(8px, 1.5vw, 16px);
    padding: clamp(48px, 5vw, 64px) 24px;
  }
  &__inner {
    max-width: 950px;
    width: 100%;
    margin-inline: auto;
  }
  &__title {
    margin-bottom: 48px;
    line-height: 1.44;
  }
}
