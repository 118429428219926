@use "../base" as *;

.btn {
  display: block;
  color: $text-gray;
  text-align: center;
  font: 600 1.125rem/1 $default-font; //18px
  text-transform: capitalize;


  /* sizes */
  &--size {
    &-main{
      padding: 15px 26px;
      border-radius: 24px;
      min-width: 200px;
      max-width: fit-content;
    }

  }
  &--fluid {
    max-width: unset;
    width: 100%;
  }

  /* colors */
  &--accent-orange {
    background-color: $accent-orange;
    @include hover-supported {
      background-color: #db5200;
    }
  }

}
