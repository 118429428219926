@use "../base" as *;


// slider block
.slick-slider {
  position: relative;
  &:not(.slick-initialized) {
    visibility: hidden;
  }

  //default dots styling
  &--dots-default .slick-dots{
    @include position($position: absolute, $left: 50%, $right: false, $bottom: false, $top: calc(100% + 16px), $width: auto);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
    z-index: 1;
    .slick-active button {
      transform: scale(1.5);
      background-color: $accent-orange;
    }
    button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #D9D9D9;
      font-size: 0;
      transition: background-color .25s ease, transform .25s ease;
    }
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .slick-track {
    position: relative;
    display: flex;
  }
  .slick-slide{
    //margin-inline:10px;
  }
  img {
    opacity: 1;
  }
}
