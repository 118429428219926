@use "../base" as *;

.site-header {
  @include position($position: fixed, $left: 0, $right: false, $bottom: false, $top: 0);
  background-color: $bg-gray;
  z-index: 1001;
  border-bottom: 1px solid $gray;
  &__inner {
    padding-block: 12px;
    gap: clamp(30px, 3vw, 43px);
  }
  &__beta-badge {
    font-size: 0.75rem; //12px
    line-height: 1;
    padding: 2px 6px;
    border-radius: 8px 2px 8px 2px;
    background-image: $gradient-beta;
  }

  // menu
  .site-navigation  {
    font: 600 1.125rem/1 $ff-nunito; //18px
    color: $title-black;
  }

  .sandwich-btn {
    padding: 4px;
    position: relative;
    z-index: 20;
    width: 27px;
    height: 22px;
    margin-right: -4px;
    &.active {
      span {
        opacity: 0;
        width: 0;
        margin-block: 0;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
        bottom: 3px;
      }
    }
    span {
      width: 100%;
    }
    span, &:before, &:after {
      display: block;
      height: 2px;
      background-color: $accent-orange;
      border-radius: 6px;
      transition: .25s ease-in-out;
    }
    &:before, &:after {
      @include pseudo($content: '', $position: absolute);
      width: calc(100% - 8px);
      transform-origin: left center;
    }
    &:before {
      top: 4px;
    }
    &:after {
      bottom: 4px;
    }
    span {
      margin-block: 4px;
    }
  }

  // min-width 992px
  @include min-width($laptop) {
    // menu
    .site-navigation {
      .navigation-menu {
        gap: 15px clamp(20px, 2.7vw, 38px);
        &__item a {
          padding: 5px;
          @include hover-supported {
            color: $accent-orange;
          }
        }
      }

      .right-side-links {
        a {
          padding: 15px 26px;
          border-radius: 24px;
          transition: $btn-transition;
        }
        :first-child {
          @include hover-supported {
            background-color: $gray;
          }
        }
        :last-child {
          background-color: black;
          color: white;
          @include hover-supported {
            box-shadow: 0px 1px 0px rgba(17,17,26,0.05), 0px 0px 8px rgba(17,17,26,0.1);
          }
        }
      }
    }
    .sandwich-btn {
      display: none;
    }

    // lang switcher
    .lang-switcher {
      position: relative;
      margin-right: clamp(15px, 2vw, 20px);
      border-radius: 8px 8px 0 0;
      transition: $transition-300;
      &:hover {
        background-color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        i {
          transform: translateY(10%) rotate(45deg);
        }
        ul {
          @include visible(1);
        }
      }
      &__current {
        padding: 5px 10px;
        cursor: pointer;
        i {
          margin-bottom: 2px;
          transition: $transition-300;
        }
      }

      ul {
        @include position($position: absolute, $left: 0, $right: false, $bottom: false, $top: 100%);
        gap: 4px;
        padding-bottom: 5px;
        background-color: white;
        border-radius: 0 0 8px 8px;
        transition: $transition-300;
        @include hidden();
        a {
          padding: 5px 10px;
          @include hover-supported {
            color: $accent-orange;
          }
        }
      }
    }
  }

  // max-width 991.9
  @include max-width($tablet) {
    &__inner {
      padding-block: 6px;
    }
    &__logo {
      gap: 8px;
      img {
        max-width: 114px;
      }
    }
    &__logo, .sandwich-btn {
      position: relative;
      z-index: 10;
    }
    .site-navigation {
      @include hidden;
      position: fixed;
      top: 47px;
      left: -100%;
      flex-direction: column;
      gap: 20px;
      align-items: start;
      max-width: 375px;
      width: 100%;
      height: calc(100% - 47px);
      background-color: $bg-gray;
      padding: 24px 24px 44px 24px;
      transition: $transition-300;
      &.active {
        @include visible;
        left: 0;
      }

      &__right-side {
        width: 100%;
        justify-content: center;
      }

      .right-side-links {
        flex-direction: column;
        gap: 24px;
        width: 100%;
        a {
          display: block;
          width: 100%;
          padding: 13px 26px;
          font: 600 16px/1 $default-font;
          text-align: center;
          border-radius: 24px;
        }
        :first-child {
          background-color: $gray;
        }
        :last-child {
          background-color: black;
          color: white;
        }
      }
    }
    .site-navigation {
      overflow-y: auto;
    }
    .site-navigation .navigation-menu {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: unset;
      gap: 50px;
      font-size: 28px;
      padding-top: 30px;
      margin-bottom: 20px;
      .lang-switcher {
        display: block;
        &.active i{
          transform: translateY(10%) rotate(45deg);
        }
        i {
          margin-bottom: 2px;
          transition: $transition-300;
        }
        ul {
          display: none;
          padding: 15px 0 0 12px;
          font-size: 1.5rem; //24px
          li:not(:last-of-type) {
            margin-bottom: 5px;
          }
          a {
            padding: 5px;
          }
        }
      }
    }
  }

}
