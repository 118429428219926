@use "../base" as *;

.scheme-section {
  .scheme-columns {
    display: flex;
    justify-content: center;
  }

  .scheme-center-column {
    position: relative;

    img {
      position: relative;
      z-index: 2;
    }
  }

  .scheme-label {
    font-size: clamp(8px, min(1.7vw, 2.5vh), 18px);
    font-weight: 600;
    line-height: 1;
    text-transform: capitalize;
    color: $accent-orange;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: clamp(4px, 1vw, 13px);
    padding-inline: clamp(8px, 1.5vw, 26px);
    border: 1px solid $dark-gray;
    border-radius: 16px;

    @include max-width($desktop-res) {
      border-radius: 4px;
    }
  }

  .scheme-right-side, .scheme-left-side {
    width: 35%;
    position: absolute;
    top: auto;
    bottom: 10px;
    z-index: 1;

    @include max-width(414px) {
      bottom: 4px;
    }
  }

  .scheme-left-side {
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: clamp(12px, 2vw, 32px);
    left: 0;

    .scheme-label {
      padding-inline-end: 32px;
    }

    @include max-width($desktop-res) {
      .scheme-label {
        padding-inline-end: 22px;
      }
    }

    @include max-width(686px) {
      .scheme-label {
        padding-inline-end: 12px;
      }
    }

    @include max-width(560px) {
      .scheme-label {
        padding-inline-end: 6px;
      }
    }
  }

  .scheme-right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: clamp(12px, 4.3vw, 72px);
    right: 0;

    .scheme-label {
      padding-inline-start: 32px;
    }

    @include max-width($desktop-res) {
      .scheme-label {
        padding-inline-start: 22px;
      }
    }

    @include max-width(686px) {
      .scheme-label {
        padding-inline-start: 12px;
      }
    }

    @include max-width(560px) {
      .scheme-label {
        padding-inline-start: 6px;
      }
    }
  }
}