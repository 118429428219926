@use "../base" as *;

.dropdown-list {
  $dropdown-list-transition: .25s;

  details[open]>summary {

    .dropdown-list__plus {
      background-color: #EAEAEA;
      &:after {
        transform: translate(-50%, -50%);
      }
      &:after, &:before {
        background-color: #323232;
      }
    }
  }
  details{
    padding: 24px 0;
    &:first-of-type {
      border-top: 1px solid #EAEAEA;
      border-bottom: 1px solid #EAEAEA;
    }
    &:not(:first-of-type) {
      border-bottom: 1px solid #EAEAEA;
    }
  }
  summary {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    list-style: none;
    transition: background-color $dropdown-list-transition;
    &::-webkit-details-marker {
      display:none;
    }
  }
  &__plus {
    display: block;
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $accent-orange;
    transition: $dropdown-list-transition;
    &:before, &:after {
      @include pseudo($content: '', $position: absolute, $display: block);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 2px;
      background-color: white;
      transition:  $dropdown-list-transition;
    }
    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  &__expanded {
    padding: 10px 68px 0 0;
    a {
      display: inline-block;
    }
  }

}
