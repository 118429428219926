@use "../base" as *;

.slider-block {
  &.bg-pink .slick-dots li.slick-active button:before{
    background-color: $pink-light;
  }
  &__slider {
    //overflow: hidden;
  }
  .slick-list {
    padding: 10px;
  }

  // slide item
  .block-slide {
    h3 {
      margin-bottom: 24px;
    }

    .btn {
      margin-top: 40px;
    }
  }

  // dots
  .slick-dots {
    @include d-flex();
    flex-wrap: wrap;
    font-size: 0;
    gap: 16px;
    li button{
      width: 24px;
      height: 24px;
      border-radius: 8px;
      overflow: hidden;
      background-color: $pink;
      transition: unset;
    }
    li.slick-active {
      button {
        @include d-flex(center, center);
        padding: 3px;
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        overflow: hidden;
        background: conic-gradient($accent-orange 0, $pink 0);
        &:before {
          @include pseudo($content: '');
          width: 100%;
          height: 100%;
          border-radius: 5px;
          background-color: $bg-gray;
        }
      }

      @for $i from 0 through 100 {
        progress[value='#{$i}'] + button{
          background: conic-gradient($accent-orange $i * 1%, $pink 0);
        }
      }
    }
  }


  // min-width 992px
  @include min-width($laptop) {
    &__slider {
      padding-bottom: 20px;
    }

    // dots
    .slick-dots {
      @include position($position: absolute, $left: 50%, $right: false, $bottom: -15px, $top: false, $width: max-content);
      transform: translateX(-50%);
      z-index: 999;
    }

    // slider row reverse
    .slider-block__slider.slider-block--row-reverse {
      .block-slide {
        flex-direction: row-reverse;
      }
      .block-slide__content {
        padding: clamp(0px, 1.2vw,20px) clamp(20px, 4.5vw, 80px) clamp(0px, 1.2vw,20px) clamp(20px, 2vw, 40px);
      }
    }
    // slider row default
    .slider-block__slider:not(.slider-block--row-reverse) .block-slide {
      .block-slide__content {
        padding: clamp(0px, 1.2vw,20px) clamp(20px, 2vw, 40px) clamp(0px, 1.2vw,20px) clamp(20px, 4.5vw, 80px);
      }
    }

    .block-slide {
      &__picture, &__content {
        flex: 1 1 50%;
      }
      &__text {
        max-width: 600px;
      }
    }

  }

  // max-width 991.9px
  @include max-width($tablet) {
    .section-title {
      padding-inline: 10px;
    }
    &__slider {
      @include d-flex(false, false, column);
    }
    .block-slide {
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: center;
      gap: 48px;
      &__content-inner > div {
        height: auto !important;
      }
      &__picture {
        @include d-flex(space-between, false, column);
        flex: 1;
        width: 100%;
        max-width: 600px;
        img {
          margin-inline: auto;
        }
      }
      .btn {
        margin-inline: auto;
      }
    }

    // dots
    .slick-dots {
      justify-content: center;
      padding-inline: 10px;
      margin-top: 20px;
      //margin-bottom: 14px;
    }
  }
}
