@use "../base" as *;

.pricing-section {

  // tab switching fade animation
  @keyframes tab-switch {
    from {
      opacity: 0;
      transform: translateY(5%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  // tab-controls
  &__tab-controls {
    max-width: 270px;
    padding: 7px;
    border-radius: 40px;
    border: 1px solid $dark-gray;
    margin-bottom: clamp(24px, 7vw, 60px);
    isolation: isolate; // to see orange shape
    background-color: white;

    button {
      flex: 1;
      padding: 15px 10px;
      color: $black;
      font: 600 #{$text-sm}/1 $default-font; //16px
      line-height: 1;
      &.active {
        color: white;
      }
      &:not(.active) {
        @include hover-supported {
          color: $accent-orange;
        }
      }
    }
    button:first-child {
      &:after {
        @include pseudo($content: '', $position: absolute, $display: block);
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        background-color: $accent-orange;
        transition: all .4s cubic-bezier(0.38, 0.8, 0.32, 1.07);
      }
      &:not(.active):after {
        transform: translateX(100%);
      }
    }

  }

  #pricing-tabs {
    position: relative;
    > div {
      display: grid;
    }
    > div.active {
      animation: tab-switch .5s ease-out;
    }
    > div.hidden {
      @include position($position: absolute, $left: 0, $right: false, $bottom: false, $top: 0, $width: 100%);
      z-index: -1;
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      //display: none;
    }

  }

  &__tab-container {
    display: grid;
    gap: 24px clamp(24px, 2.5vw, 36px);
  }

  // card
  .pricing-card {
    box-shadow: 0 0 0 1px silver inset;
    border-radius: 16px;
    background-color: white;
    padding: 30px clamp(24px, 3vw, 30px) 40px;
    transition: transform .35s ease-out;
    @include hover-supported {
      transform: translateY(1%);
    }

    // current plan
    &.current-plan .btn  {
      cursor: not-allowed;
      background-color: #A9A9A9;
    }
    // most popular plan
    &.most-popular {
      box-shadow: 0 0 0 1px $accent-orange inset;
      border-radius: 0 0 16px 16px;
      .most-popular-badge {
        @include position(absolute, 0, false, false, 0);
        color: white;
        font-size: 1.125rem;
        padding: 6px;
        border-radius: 16px 16px 0 0;
        transform: translateY(calc(-100% - -1px));
        background-color: $accent-orange;
      }
    }

    &__free-badge-wrap {
      font-size: 0.625rem; //10px
      line-height: 1;
      text-transform: uppercase;
      p {
        margin-bottom: 7px;
      }
    }
    &__free-badge {
      background: $gradient-beta;
      min-height: 22px;
      padding: 6px 12px;
      border-radius: 20px;
      max-width: fit-content;
      min-width: 105px;
      text-align: center;
    }
    &__plan-wrapper {
      margin-bottom: clamp(20px, 2vw, 30px);
    }
    &__plan {
      //flex-wrap: wrap;
      flex-direction: column;
      align-items: start;
      gap: 10px;
      h3 {
        font-size: clamp(36px, min(3.5vw, 6.3vh), 48px);
      }
    }
    &__price {
      align-items: flex-end;
      gap: 2px;
      margin-bottom: 10px;

      h3 {
        font-size: 3rem; //48px
        line-height: 1;
      }
      h4 {
        font-size: 1.5rem; //24px
      }
    }
    .btn {
      margin-block: 20px clamp(36px, 3vw, 48px);
    }
    &__content {
      line-height: 1.222;
    }

    ul {
      li:not(:last-of-type) {
        margin-bottom: 8px;
      }
      + * {
        margin-block: clamp(38px, 3vw, 48px) 8px;
      }
    }
  }

  // min-width 768px
  @include min-width($desktop-res) {

    &__tab-container {
      grid-template-columns: repeat( auto-fill, minmax(max(min(327px, 100%), (100% - 36px * 2) / 3), 1fr) );
      &.annually .pricing-card__plan-wrapper{
        min-height: 101px;
      }
      &.monthly .pricing-card__plan-wrapper {
        min-height: 118px;
      }
    }

    //.pricing-card__free-badge-wrap {
    //  display: grid;
    //  place-items: center;
    //}
  }

  // max-width 767.9px
  @include max-width($mobile-res) {
    // card
    .pricing-card {
      height: auto !important;
      // most popular plan
      &.most-popular {
        margin-top: 34px;
      }
      &__plan-wrapper {
        height: auto !important;
      }
      &__free-badge-wrap p {
        text-align: left;
      }
      &__free-badge {
        font-size: $text-xs; //14px
      }
    }
  }
}
