@use "../base" as *;

.twitter-block {

  .slick-slide {
    position: relative;
    margin-inline: 15px;
    @include max-width(1400px) {
      // invisible block for slider swipe issue
      &:after {
        @include pseudo($content: '', $position: absolute, $display: block);
        top: 50%;
        left: 0;
        transform: translateY(-57%);
        width: 100%;
        height: 53%;
        z-index: 100;
        //background-color: red;
      }
    }
  }
  .twitter-tweet {
    margin-block: 0 !important;
  }

  // min-width 768px
  @include min-width($desktop-res) {
    .container {
      width: min(100% - 28px,clamp(1330px,85vw,1470px));
    }
  }

  // max-width 767.9px
  @include max-width($mobile-res) {
    .container {
      width: 100%;
    }
    .slick-list {
      padding-inline: 12px;
    }
    .slick-slide {
      margin-inline: 12px;
    }
  }
}
