@use "../base" as *;

.first-screen {
  &__columns {
    display: grid;
    align-items: end;
    gap: 48px 40px;
  }

  &__text {
    margin-top: 24px;
  }
  h3 {
    line-height: 1.2;
  }
  &__picture-col {
    img {
      width: auto;
    }
  }

  .btn {
    grid-column: 1/-1;
    justify-self: center;
  }

  // min-width 992px
  @include min-width($laptop) {
    &__columns {
      grid-template-columns: 1fr .8fr;
    }
    &__text-col {
      max-width: 710px;
    }
    &__picture-col {
      margin-bottom: -5px;
    }
  }
  // max-width 991.9px
  @include max-width($tablet) {
    &__columns {
      justify-items: center;
    }
    &__picture-col {
      order: 3;
    }
    .btn {
      order: 2;
    }
  }
  @include max-width($mobile-res) {
    &__text-col h3 {
      line-height: 1.18;
    }
  }


  @include max-width(425px) {
    &__text-col h1{
      max-width: 310px;
    }
  }

}
