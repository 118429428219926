@use "base" as *;

body {
  font: 400 clamp(16px, min(1.7vw, 2.5vh), 18px)/1.44 $default-font;
  color: $text-black;
  background-color: $bg-gray;
  &.active {
    overflow: hidden;
    touch-action: none;
  }
  .site {
    @include d-flex($direction: column);
    position: relative;
    min-height: 100%;
    min-width: 320px;
  }
  main {
    flex: 1 0 auto;
    background-repeat: no-repeat;
    background-position: center center;
    padding-block: clamp(73px, min(14vw, 19vh), 170px) 125px;
  }
}

.container {
  &--fluid {
    @include container($maxW: 100%);
  }
  &--md {
    @include container($maxW: 1200px);
  }
  &--default {
    @include container($maxW: clamp(1300px, 85vw, 1440px));
  }
  &--carousel {
    @include container();
    width: min(100% - 28px, clamp(1320px, 85vw, 1460px)); // to prevent box-shadow from clipping
  }
}


.overlay {
  background-color: rgba(0,0,0,.5);
  position: fixed;
  inset: 0;
  @include  hidden;
  transition: opacity $transition-300, visibility $transition-300;
  z-index: 99;
  &.active {
    @include visible(1000);
  }
  &_modal {
    z-index: 1002 !important; // to cover header
  }
}

.card {
  border-radius: 16px;
  box-shadow:
          0px 2px 6px 2px rgba(67, 67, 67, 0.14),
          0px 1px 8px 0px rgba(67, 67, 67, 0.24);
  overflow: hidden;
}

.section-divider {
  position: relative;
  &--top {
    clip-path: polygon(0 0, 100% 0, 100% 97%, 0 97%);
    top: -1px;
  }
  &--bottom {
    clip-path: polygon(0 3%, 100% 3%, 100% 100%, 0 100%);
    bottom: -1px;
  }
  &--pink svg *{
    fill: $pink-light;
  }
  &--gray svg *{
    fill: $gray;
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
    background-color: $bg-gray;
    transform: translate3d(0, 0, 0);
  }

  // max width 1400
  @include max-width(1400px) {
    &--top {
      margin-bottom: 40px;
    }
    &--bottom {
      margin-top: 40px;
    }
  }
}
